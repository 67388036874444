import { useEffect } from 'react';
import SEO from './seo/SEO';

import { aboutUsData } from '../data/commonData';

import BackToTopButton from './buttons/BackToTopButton';
import OurPartners from './carousel/OurPartners';

function AboutUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const aboutUsInfo = aboutUsData.info;
    const aboutUsAdv = aboutUsData.advantages;

    return (
        <>
            <SEO
                title={'За Нас - Денас Център'}
                description={'Вашето място за красота, естетика и медецинска козметика.'}
                canonicalUrl={'https://www.denasbg.com/about'}
            />

            {/* Promotional Text */}
            <div className="container-xxl py-3" style={{ backgroundColor: '#ffebeb' }}>
                <div className="container text-center">
                    <h2 className="text-danger" style={{ fontWeight: 'bold' }}>
                        Лятна Промоция: Вземете 30% отстъпка на всички процедури за лице и тяло, и 20% отстъпка от закупен пакет за процедури до края на месеца!
                    </h2>
                    <p className="text-danger">
                        Побързайте и запишете час сега, за да се възползвате от нашите невероятни предложения!
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src="img/about-1.jpg" alt="" style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-3">За Нас</h6>
                            <h1 className="mb-4">Добре дошли в <span className="text-primary">Денас</span></h1>
                            {aboutUsInfo &&
                                aboutUsInfo.map((x, i) =>
                                    <p key={i} className="mb-4">
                                        {x}
                                    </p>
                                )}
                            <div className="row gy-2 gx-4 mb-4">
                                {aboutUsAdv &&
                                    aboutUsAdv.map((keyPoint, idx) =>
                                        <div className="col-sm-6" key={idx}>
                                            <p className="mb-0" ><i className="fa fa-arrow-right text-primary me-2"></i>{keyPoint}</p>
                                        </div>)
                                }
                            </div>
                            {/* <a className="btn btn-primary py-3 px-5 mt-2" href="">Read More</a> */}
                        </div>
                    </div>
                </div>
                {<BackToTopButton />}
            </div>
            <OurPartners />
        </>
    );
}

export default AboutUs;