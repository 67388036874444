const contactData = {
    country: 'България',
    city: 'гр. Варна',
    street: 'ул. Евлоги Георгиев 1',
    phoneNumber: '+359 882 21 12 74',
    email: 'recreation.center17@gmail.com',
};

const aboutUsData = {
    info: ['Добре дошли в Денас Център БГ, вашата първокласна дестинация за здраве и красота в град Варна. Екипът от специалисти, най-висок клас съвременна апаратура, натрупания богат опит и познания в сферата на естетиката, медицинската козметика, лечение и физиотерапия ни превърнаха в дом за здраве и красота.', 'Сътрудничеството с най-големите водещи научно-изследователски компании за производство на качествени продукти и консумативи, затвърди доверието на нашите клиенти през годините. При нас ще получите задълбочена консултация и обслужване с непрестанен стремеж към перфекционализъм.'],
    advantages: ['Висок клас апаратура', 'Персонализирани консултации', 'Професионалисти с над 10г. опит', 'Първокласни козметични процедури',],
};

export {
    contactData,
    aboutUsData,
};