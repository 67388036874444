import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SEO from './seo/SEO.js';
import BackToTopButton from './buttons/BackToTopButton.js';


function Promotions() {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <SEO
                title={'Промоции - Денас Център'}
                description={'Тук може да намерите нашите актуални промоции - луксозни процедури за грижа за лицето и тялото на отлични цени.'}
                canonicalUrl={'https://www.denasbg.com/promotions'}
            />

            <div className="container-xxl py-5 destination">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Промоции</h6>
                        <h1 className="mb-5">Популярни промоции</h1>
                    </div>
                    {/* Promotional Text */}
                    <div className="container-xxl py-3" style={{ backgroundColor: '#ffebeb' }}>
                        <div className="container text-center">
                            <h2 className="text-danger" style={{ fontWeight: 'bold' }}>
                                Лятна Промоция: Вземете 30% отстъпка на всички процедури за лице и тяло, и 20% отстъпка от закупен пакет за процедури до края на месеца!
                            </h2>
                            <p className="text-danger">
                                Побързайте и запишете час сега, за да се възползвате от нашите невероятни предложения!
                            </p>
                        </div>
                    </div>
                    <br />
                    {/* Promotional Text End */}
                    <div className="row g-3">
                        <div className="col-lg-7 col-md-6">
                            <div className="row g-3">
                                <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                    <Link to={'/eyelashes-procedures/laminiranemigli'} className="position-relative d-block overflow-hidden">
                                        <img className="img-fluid" src="img/eyebrows-promo.jpg" alt="" />
                                        <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">30% отстъпка</div>
                                        <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Ламиниране на мигли</div>
                                    </Link>
                                </div>
                                <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                    <Link to={'/body-procedures/verju'} className="position-relative d-block overflow-hidden">
                                        <img className="img-fluid" src="img/verju-3.jpg" alt="" />
                                        <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">30% отстъпка</div>
                                        <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Лазер за отслабване Verju</div>
                                    </Link>
                                </div>
                                <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                    <Link to={'/body-procedures/btl-xwave'} className="position-relative d-block overflow-hidden">
                                        <img className="img-fluid" src="img/x-wave-promo.jpg" alt="" />
                                        <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">30% отстъпка</div>
                                        <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">BTL X-wave</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{ minHeight: '350px' }}>
                            <Link to={'/face-procedures/biorepeel'} className="position-relative d-block h-100 overflow-hidden">
                                <img className="img-fluid position-absolute w-100 h-100" src="img/biorepeel-promo.jpg" alt="" style={{ objectFit: 'cover' }} />
                                <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">30% отстъпка</div>
                                <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">BioRePeel Cl3 пилинг</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <BackToTopButton />
            </div>
        </>
    );
};

export default Promotions;